import "./App.css";
import Pagseguro from "../src/components/Pagseguro";

function App() {
  return (
    <div className="app-div">
      <div className="pagseguro-div" >
        <Pagseguro />
      </div>
    </div>
  );
}

export default App;
