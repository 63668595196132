import React from "react";
import styled, { css } from "styled-components";
import { DirectPayment, Loading } from "pagseguro-react";
import config from "./config";
import axios from "axios";

const Home = styled.div`
  position: relative;
  
.ps-react-tab-payment-method:first-child, .ps-react-tab-payment-method:last-child{
    display: none;
  }
`;

const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(245, 245, 245, 0.9);

  div {
    left: 50%;
    top: 40%;
  }
`;

const Feedback = styled.div`
  background: #f5f5f5;
  padding-top: 50px;
  padding-bottom: 50px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.18);
  text-align: center;
  width: 100%;

  h1 {
    font-size: 1.5em;
    color: #08bd75;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: table;
  }

  ${(props) =>
    props.error &&
    css`
      background: none;
      color: red;
      h1 {
        color: red;
      }
    `}
`;

export default class Component extends React.Component {
  /**
   * constructor
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadResponse: false,
      agendamento: null,
      error: null,
      success: null,
      paymentLink: null,
      paid: false,
      afiliadoCode: "",
      idService: "",
      session: "",

      env: "",

      // Informações do comprador
      sender: {
        name: "",
        email: "",
        phone: {
          areaCode: "",
          number: "",
        },
        document: {
          type: "CPF",
          value: "",
        },
      },

      // Endereço de entrega
      shipping: {
        addressRequired: false, //Aqui decidimos se vai ter frete ou não.
        // cost: "100"
        /*
				type: 3,
				cost: 10.00,
				street: 'Av Joao Lima',
				number: 55,
				complement: 'Casa',
				district: 'Campeche',
				city: 'Florianopolis',
				state: 'SC',
				country: 'BRA',
				postalCode: '88063333'
				*/
      },

      // Endereço de cobrança
      billing: {
        // street: "Av Joao Lima",
        // number: 55,
        // complement: "Casa",
        // district: "Centro",
        // city: "Cachoeirinha",
        // state: "PE",
        country: "BRA",
        // postalCode: "55380000",
      },

      // produtos

      items: [
        {
          // 	id: "",
          // 	description: "",
          // 	quantity: "",
          // 	amount: "",
        },
      ],

      // Cartão de crédito
      creditCard: {
        //pagamento parcelado sem juros: PRECISA SER MAIOR MAIOR OU IGUAL A "2" !
        maxInstallmentNoInterest: 12,
      },

      extraAmount: 0,
      reference: "",
    };
  }

  componentDidMount() {
    const { session } = this.state;
    const result = new URLSearchParams(window.location.search);

    const paymentPayload = result.get("payment");

    let decodedPaymentPayload;

    console.log("agendamento", this.state.agendamento);

    try {
      decodedPaymentPayload = JSON.parse(atob(paymentPayload));
    } catch (e) {
      alert("Objeto de pagamento inválido!");
      return;
    }

    if (!session) {
      axios
        .post(`${config.endpoint}/session`)
        .then((res) => {
          this.setState({ session: res.data.content, loadResponse: true });
        })
        .catch((err) => console.error(err));
    }

    axios
      .get(
        `${config.endpoint}/api/agentamento/` +
          decodedPaymentPayload.idAgendamento,
        {
          headers: {
            Authorization: decodedPaymentPayload.token,
          },
        }
      )
      .then((response) => {
        console.log("response", response.data);

        this.setState({
          agendamento: decodedPaymentPayload.idAgendamento,
          loadResponse: response.data,
          billing: {
            street: response.data.enderecoEntrega.rua,
            number: response.data.enderecoEntrega.numero,
            complement: response.data.enderecoEntrega.complemento,
            district: response.data.enderecoEntrega.bairro,
            city: response.data.enderecoEntrega.cidade,
            state: response.data.enderecoEntrega.uf,
            postalCode: response.data.enderecoEntrega.cep,
            country: "BRA",
          },
          items: {
            quantity: 1,
            amount: response.data.valor,
            description: response.data.nome,
            id: response.data.id,
          },
          sender: {
            name: response.data.nomeClinete,
            email: response.data.email,
            phone: {
              areaCode: response.data.telefone.ddd,
              number: response.data.telefone.numero,
            },
            document: {
              type: response.data.type,
              value: String(response.data.value).replace(/\D+/g, ""),
            },
          },
        });
      });
  }

  onSubmit(data) {
    console.log("sending to API...");
    console.log(data);
    data.afiliadoCode = this.state.afiliadoCode;
    data.idService = this.state.idService;
    this.setState({
      loading: true,
      error: null,
      success: "",
      paymentLink: null,
    });

    axios
      .post(`${config.endpoint}/directPayment/payment`, data)
      .then((res) => {
        const { content } = res.data;

        let newState = {};

        switch (content.paymentMethod.typeId) {
          case 2:
            newState = {
              success: "Acesse o link abaixo para imprimir o boleto",
              paymentLink: content.paymentLink,
            };
            break;

          case 3:
            newState = {
              success: "Acesse seu banco e finalize a transação",
              paymentLink: content.paymentLink,
            };
            break;

          case 1:
            newState = {
              success: "Pagamento realizado com sucesso",
            };
            break;
          default:
        }

        this.setState({
          paid: true,
          loading: false,
          ...newState,
        });
      })
      .catch((err) => {
        const { content } = err.response.data;
        const error = Array.isArray(content) ? content : [content];
        this.setState({
          loading: false,
          error,
paymentLnk: null,
        });
      });
  }

  /**
   * onError
   */
  onError(error) {
    console.error(error);
  }

  /**
   * render
   */
  render() {
    if (!this.state.session) {
      return null;
    }

    console.log("resultado:", this.state);

    return (
      this.state.agendamento && (
        <Home>
          {this.state.loading && (
            <LoadingContainer>
              <Loading />
            </LoadingContainer>
          )}

          {!this.state.paid && (
            <DirectPayment
              env="production"
              session={this.state.session}
              extraAmount={this.state.extraAmount}
              reference={this.state.reference}
              creditCard={this.state.creditCard}
              sender={this.state.sender}
              shipping={this.state.shipping}
              billing={this.state.billing}
              items={[this.state.items]}
              hiddenShippingForm
              exclude={
                [
                  // 'CREDIT_CARD',
                  'ONLINE_DEBIT',
                  //'BOLETO'
                ]
              }
              onError={this.onError.bind(this)}
              onSubmit={this.onSubmit.bind(this)}
              /*
					hiddenSenderForm
					hiddenShippingForm 
					hiddenBillingForm
				*/
            />
          )}

          {this.state.success && (
            <Feedback>
              <h1>{this.state.success}</h1>
            </Feedback>
          )}

          {this.state.error && (
            <Feedback error>
              <ul>
                {/*this.state.error.map((error, key) => (
								<li key={key}>
									{error.code} - {error.message}
								</li>
							))*/}
              </ul>
            </Feedback>
          )}

          {this.state.paymentLink && (
            <Feedback>
              <a
                href={this.state.paymentLink}
                target="_blank"
                rel="noreferrer noopener"
              >
                ACESSAR
              </a>
            </Feedback>
          )}
        </Home>
      )
    );
  }
}
